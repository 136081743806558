import React from "react";
import clsx from "clsx";
// import { Image } from "@atoms";

const CbImage = ({ image, caption, fluid }) => {
  return (
    <div className={clsx("flex my-8 w-full")}>
      <figure classNames="w-full">
        <img
          src={image?.url}
          className="w-full"
          style={
            image?.focalPoint
              ? {
                  objectPosition: `${image.focalPoint[0] * 100}% ${image.focalPoint[1] * 100}%`,
                }
              : undefined
          }
        />
        {caption && (
          <figcaption className="font-serif italic text-xs text-blue mt-2">
            {caption}
          </figcaption>
        )}
      </figure>
      {/* <Image {...image} caption={caption} /> */}
    </div>
  );
};

export default CbImage;
