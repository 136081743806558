"use client";

import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";

export const useDots = emblaApi => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const onDotClick = useCallback(
    index => {
      if (!emblaApi) return;
      emblaApi.scrollTo(index);
    },
    [emblaApi]
  );

  const onInit = useCallback(() => {
    if (!emblaApi) return;
    setScrollSnaps(emblaApi.scrollSnapList());
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onInit();
    onSelect();
    emblaApi.on("reInit", onInit).on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onInit, onSelect]);

  return {
    selectedIndex,
    scrollSnaps,
    onDotClick,
  };
};

export const DotButton = ({ className, ...rest }) => {
  return (
    <button
      type="button"
      className={clsx(
        "size-4 rounded-full border-2 border-gold duration-200",
        className
      )}
      {...rest}
    />
  );
};
