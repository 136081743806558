import React from "react";
import clsx from "clsx";
import { Text, Button } from "@atoms";
import { AppLink } from "@base";

const Card = ({ title, image, description, button, horizontal, even }) => {
  return (
    <AppLink
      to={button?.url}
      className={clsx("!block group", {
        "first:pt-0 last:pb-0 py-8 md:py-16": horizontal,
      })}
    >
      <div
        className={clsx(
          "flex text-left items-start",
          { "grid grid-cols-1 md:grid-cols-2 gap-8": horizontal },
          { "flex-col": !horizontal }
        )}
      >
        <div
          className={clsx(
            "transition duration-200 group-hover:opacity-70",
            { "order-0 md:order-2": horizontal && even },
            { "mb-6": !horizontal }
          )}
        >
          <img
            src={image?.url}
            className="w-full"
            style={
              image?.focalPoint
                ? {
                    objectPosition: `${image.focalPoint[0] * 100}% ${image.focalPoint[1] * 100}%`,
                  }
                : undefined
            }
          />
        </div>
        <div className="space-y-4">
          <Text
            variant="h4"
            className="text-blue text-2xl transition duration-200 group-hover:opacity-70"
          >
            {title}
          </Text>
          <Text variant="sm" className="text-pretty">
            {description}
          </Text>
          <Button color="gold" size="sm">
            Read More
          </Button>
        </div>
      </div>
    </AppLink>
  );
};

export default Card;
