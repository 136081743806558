import React from "react";
import { Container, Text, Button } from "@atoms";
import { useCultureCareerData } from "@staticQueries";
import { AppLink } from "@base";

const CultureCompany = () => {
  const { culture, careers, jobs } = useCultureCareerData();

  return (
    <section className="bg-gold py-12 md:py-16">
      <Container variant="lg">
        <div className="grid grid-cols-1 md:grid-cols-2 mt-8 gap-10">
          <div>
            <img
              src={culture?.image?.url}
              className="w-full"
              style={
                culture?.image?.focalPoint
                  ? {
                      objectPosition: `${culture?.image.focalPoint[0] * 100}% ${culture?.image.focalPoint[1] * 100}%`,
                    }
                  : undefined
              }
            />
            <Text variant="h4" className="text-white mt-6 mb-4">
              {culture?.title}
            </Text>
            <Text variant="sm" className="text-white">
              {culture?.description}
            </Text>
          </div>

          <div>
            <img
              src={careers?.image?.url}
              className="w-full"
              style={
                careers?.image?.focalPoint
                  ? {
                      objectPosition: `${careers?.image.focalPoint[0] * 100}% ${careers?.image.focalPoint[1] * 100}%`,
                    }
                  : undefined
              }
            />
            <Text variant="h4" className="text-white mt-6 mb-4">
              {careers?.title}
            </Text>
            <Text variant="sm" className="text-white">
              {careers?.description}
            </Text>

            {!!jobs?.length && (
              <ul className="my-4 divide-y-2 divide-gold-border border-b-2 border-gold-border">
                {jobs?.map((job, i) => {
                  return (
                    <li className="flex-row" key={i}>
                      <AppLink
                        to={job?.url}
                        className="!flex flex-row justify-between items-center py-4 group"
                      >
                        <div className="group-hover:opacity-70 transition duration-200">
                          <Text
                            variant="h6"
                            className="text-white normal-case tracking-normal"
                          >
                            {job?.title}
                          </Text>
                          <Text variant="sm" className="italic text-white">
                            {job?.location}
                          </Text>
                        </div>
                        <div>
                          <Button size="sm">More Info</Button>
                        </div>
                      </AppLink>
                    </li>
                  );
                })}
              </ul>
            )}
            <Button to="/company/careers" size="sm">
              View All Jobs
            </Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default CultureCompany;
