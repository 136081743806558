const formatDate = date => {
  const dt = new Intl.DateTimeFormat("en", {
    month: "long",
    year: "numeric",
  }).format(new Date(date));
  // this mimics the original moment string, craft uses @formatDateTime(format: "MMMM, Y")
  return dt.split(" ").join(", ");
};

export default formatDate;
