"use client";

import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { Icon } from "@atoms";

export const useArrows = emblaApi => {
  const [prevDisabled, setPrevDisabled] = useState(true);
  const [nextDisabled, setNextDisabled] = useState(true);

  const onPrevClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback(() => {
    if (!emblaApi) return;
    setPrevDisabled(!emblaApi.canScrollPrev());
    setNextDisabled(!emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;

    onSelect(emblaApi);
    emblaApi.on("reInit", onSelect).on("select", onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevDisabled,
    nextDisabled,
    onPrevClick,
    onNextClick,
  };
};

export const PrevButton = ({ className, ...rest }) => {
  return (
    <button
      className={clsx(
        "flex size-8 items-center justify-center rounded-full border-2 border-gold bg-gold text-white duration-200 disabled:border-gray disabled:bg-transparent disabled:text-gray",
        className
      )}
      type="button"
      title="view previous slide"
      aria-label="view previous slide"
      {...rest}
    >
      <Icon name="chevron" className="size-2" />
    </button>
  );
};

export const NextButton = ({ className, ...rest }) => {
  return (
    <button
      className={clsx(
        "flex size-8 rotate-180 items-center justify-center rounded-full border-2 border-gold bg-gold text-white duration-200 disabled:border-gray disabled:bg-transparent disabled:text-gray",
        className
      )}
      type="button"
      title="view next slide"
      aria-label="view next slide"
      {...rest}
    >
      <Icon name="chevron" className="size-2" />
    </button>
  );
};
