import React from "react";
import clsx from "clsx";
import { Container } from "@atoms";

const CardContainer = ({ children, grid, grayBackground }) => {
  return (
    <section
      className={clsx(
        "py-14",
        { "bg-white": !grayBackground },
        { "bg-blue-light": grayBackground }
      )}
    >
      <Container
        variant="lg"
        className={clsx(
          "",
          { "grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-16": grid },
          { "divide-y-0 md:divide-y-2 divide-white": grayBackground }
        )}
      >
        {children}
      </Container>
    </section>
  );
};

export default CardContainer;
