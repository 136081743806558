import React from "react";
import clsx from "clsx";
import { Text } from "@atoms";
import { AppLink } from "@base";

const CbQuote = ({ quote, author, title, image, link }) => {
  return (
    <div className={clsx("py-8 bg-blue-light italic", {})}>
      <blockquote className="px-8">
        <Text variant="xl" className="italic font-bold mb-4" quotes>
          {quote}
        </Text>

        <div className="flex items-center my-2">
          {/* TODO: replace with <img> */}
          <div
            className="w-16 h-16 bg-center bg-cover rounded-full overflow-hidden"
            style={{ backgroundImage: `url(${image?.url})` }}
          />
          <figcaption className="ml-4">
            <Text variant="sm">{author}</Text>
            <Text variant="sm" className="text-blue">
              {title}
            </Text>
          </figcaption>
        </div>
      </blockquote>
      {link?.url && (
        <AppLink className="text-gold mx-12">
          <Text variant="body" className="italic border-b border-gold">
            {link?.text}
          </Text>
        </AppLink>
      )}
    </div>
  );
};

export default CbQuote;
