"use client";

import React from "react";
import clsx from "clsx";
import useEmblaCarousel from "embla-carousel-react";
import { PrevButton, NextButton, useArrows } from "@atoms/CarouselArrows";
import { DotButton, useDots } from "@atoms/CarouselDots";

const Carousel = ({
  component: Component,
  maxVisible = 3,
  options,
  showDots,
  slides,
}) => {
  const [viewport, emblaApi] = useEmblaCarousel(
    {
      align: maxVisible > 1 ? "start" : "center",
      ...options,
    },
    // plugins such as AutoHeight() go here
    []
  );

  const { prevDisabled, nextDisabled, onPrevClick, onNextClick } =
    useArrows(emblaApi);

  const { selectedIndex, scrollSnaps, onDotClick } = useDots(emblaApi);

  return (
    <div className="flex flex-col gap-8">
      {/* carousel container */}
      <div className="relative">
        <div className="absolute inset-y-0 -left-4 z-10 flex items-center md:-left-12">
          <PrevButton onClick={onPrevClick} disabled={prevDisabled} />
        </div>
        {/* slide viewport */}
        <div ref={viewport} className="overflow-hidden">
          <div className="-ml-8 flex">
            {slides?.map(slide => {
              return (
                <div
                  key={slide.uid}
                  className={clsx(
                    "min-w-0 max-w-full items-center flex shrink-0 grow-0 basis-full pl-8",
                    {
                      "lg:basis-1/2": maxVisible === 2,
                      "lg:basis-1/3": maxVisible === 3,
                      "lg:basis-1/4": maxVisible === 4,
                    }
                  )}
                >
                  <Component {...slide} />
                </div>
              );
            })}
          </div>
          <div className="absolute inset-y-0 -right-4 z-10 flex items-center md:-right-12">
            <NextButton onClick={onNextClick} disabled={nextDisabled} />
          </div>
        </div>
      </div>
      {/* dot navigation */}
      {showDots && (
        <div className="flex w-full justify-center">
          <div className="flex flex-wrap gap-4">
            {scrollSnaps?.map((_, i) => {
              return (
                <DotButton
                  // eslint-disable-next-line react/no-array-index-key
                  key={`dot--${i}`}
                  onClick={() => onDotClick(i)}
                  className={clsx({
                    "bg-gold": i === selectedIndex,
                    "bg-transparent": i !== selectedIndex,
                  })}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Carousel;
