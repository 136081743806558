module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3_cyxwj2alfbzox6jubbxo5hrky4/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","display":"minimal-ui","icon":"./static/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e7bc74d1c5da8005a72c57c1445435e8"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-transition-link@1.20.5_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"/opt/build/repo/src/components/layout/Default.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@8.57.1__react-dom@18.3.1_react@18.3.1__react@18.3.1__moglmpdmtq4v6tonrt7povu3ly/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
